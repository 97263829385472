/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
  isProjects: true, // Change this to false if you don't want Projects section
};

//SEO Related settings
const seo = {
  title: "Nitesh's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Nitesh Purohit Portfolio",
    type: "website",
    url: "http://niteshpurohit.com/",
  },
};

//Home Page
const greeting = {
  title: "Nitesh Purohit",
  logo_name: "NiteshPurohit",
  nickname: ">thinker<",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/file/d/1MWpR8tgyzeKDdZDU6rVwhVxH5xjmrS8J/view?usp=sharing",
  portfolio_repository: "https://github.com/niteshpurohit/niteshpurohit",
  githubProfile: "https://github.com/niteshpurohit",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/niteshpurohit",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/niteshpurohit/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:nitesh.purohit.it@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website using React, Angular and Flutter",
        "⚡ Developing mobile applications using Flutter and Android",
        "⚡ Creating robust middleware using Ruby on Rails, NodeJS and Go",
        "⚡ Integration of third party services such as Firebase/ AWS / GCP",
        "⚡ Experience with RESTful API and GraphQL",
        "⚡ Experience with WebSockets and Socket.io",
      ],
      softwareSkills: [
        {
          skillName: "Ruby",
          fontAwesomeClassname: "simple-icons:ruby",
          style: {
            color: "#CC342D",
          },
        },
        {
          skillName: "Ruby on Rails",
          fontAwesomeClassname: "simple-icons:rubyonrails",
          style: {
            color: "#CC0000",
          },
        },
        {
          skillName: "GraphQL",
          fontAwesomeClassname: "simple-icons:graphql",
          style: {
            color: "#E10098",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#00599C",
          },
        },
        {
          skillName: "Go",
          fontAwesomeClassname: "simple-icons:go",
          style: {
            color: "#00ADD8",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "React",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Angular",
          fontAwesomeClassname: "simple-icons:angular",
          style: {
            color: "#DD0031",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Using terraform/aws cdk to deploy scalable infrastructure",
        "⚡ Experience with Docker and Kubernetes",
        "⚡ Experience with CI/CD tools like Jenkins, Github Actions, Buildkite and CircleCI",
        "⚡ Experience with monitoring tools like New Relic, Datadog and Sentry",
        "⚡ Experience with databases like PostgreSQL, MongoDB, MySQL and DynamoDB and caching systems like Redis",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Redis",
          fontAwesomeClassname: "logos:redis",
          style: {
            color: "#DC382D",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Terraform",
          fontAwesomeClassname: "simple-icons:terraform",
          style: {
            color: "#326CE5",
          },
        },
        {
          skillName: "Github Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#2088FF",
          },
        },
        {
          skillName: "Jenkins",
          fontAwesomeClassname: "simple-icons:jenkins",
          style: {
            color: "#D24939",
          },
        },
        {
          skillName: "CircleCI",
          fontAwesomeClassname: "simple-icons:circleci",
          style: {
            color: "#343434",
          },
        },
        {
          skillName: "New Relic",
          fontAwesomeClassname: "simple-icons:newrelic",
          style: {
            color: "#008C99",
          },
        },
        {
          skillName: "Datadog",
          fontAwesomeClassname: "simple-icons:datadog",
          style: {
            color: "#632CA6",
          },
        },
        {
          skillName: "Sentry",
          fontAwesomeClassname: "simple-icons:sentry",
          style: {
            color: "#FB4226",
          },
        },
      ],
    },
    {
      title: "Data Science & ETL",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing highly scalable production ready scripts for data processing",
        "⚡ Experience in building ETL pipelines",
      ],
      softwareSkills: [
        {
          skillName: "AWS Athena",
          fontAwesomeClassname: "logos:aws-athena",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "AWS Kinesis & Firehose",
          fontAwesomeClassname: "logos:aws-kinesis",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Scala",
          fontAwesomeClassname: "logos:scala",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Apache Spark",
          fontAwesomeClassname: "logos:apache-spark",
          style: {
            backgroundColor: "transparent",
            color: "#E25A1C",
          },
        },
        {
          skillName: "Databricks",
          fontAwesomeClassname: "simple-icons:databricks",
          style: {
            backgroundColor: "transparent",
            color: "#1388C2",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [],
};

const degrees = {
  degrees: [
    {
      title: "Jodhpur National University",
      subtitle: "B.Tech. in Information Technology",
      logo_path: "jnu.png",
      alt_name: "JECRC Jodhpur",
      duration: "2008 - 2012",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, i have done courses on Robotics, Embedded Systems, IoT, Cloud Computing and Full Stack Development.",
        "⚡ I have also done some projects on Data Science and Machine Learning.",
      ],
      website_link: "https://jodhpurnationaluniversity.co.in/",
    },
  ],
};

const certifications = {
  certifications: [],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work experiences",
  description:
    "I have worked with various companies and startups as a Software Engineer, Development Manager and Head of Engineering. I have experience in building scalable and robust web applications, mobile applications, data science projects and cloud infrastructure.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Senior Software Engineer",
          company: "Lillio (Formerly Himama)",
          company_url: "https://lillio.com",
          logo_path: "lillio.svg",
          duration: "November 2021 - Present",
          location: "Toronto, Canada",
          description:
            "Working on various projects related to Web Development, GraphQL using Ruby on Rails and React",
          color: "#0879bf",
        },
        {
          title: "Principal Engineer",
          company: "Jobstreet Malaysia",
          company_url: "https://jobstreet.com.my",
          logo_path: "jobstreet.png",
          duration: "October 2019 - November 2021",
          location: "Kuala Lumpur, Malaysia",
          description:
            "Worked on various projects related to Web Development, Data Science and Cloud Infrastructure using NodeJS, React, Python, Go and AWS",
          color: "#0879bf",
        },
        {
          title: "Head of Engineering",
          company: "AJM Softwares",
          company_url: "https://ajm.in",
          logo_path: "ajm.jpg",
          duration: "November 2018 - October 2019",
          location: "Jodhpur, Rajasthan, India",
          description:
            "Worked on various projects and managed multiple teams in domains such as Web Development, Mobile Development, Data Science, and Cloud Infrastructure.",
          color: "#0879bf",
        },
        {
          title: "Development Manager",
          company: "Finterra",
          company_url: "https://finterra.org",
          logo_path: "finterra.svg",
          duration: "January 2018 - October 2018",
          location: "Kuala Lumpur, Malaysia",
          description:
            "Worked on various projects and managed multiple teams in domains such as Web Development, Mobile Development, Cryptocurrency and Web3.",
          color: "#0879bf",
        },
        {
          title: "Sole Proprietor",
          company: "AWR Technologies",
          company_url: "https://www.awrtechnologies.com",
          logo_path: "awr.png",
          duration: "July 2014 - December 2018",
          location: "Jodhpur, Rajasthan, India",
          description:
            "Worked on various projects and managed multiple teams in domains such as Web Development, Mobile Development, Data Science, and Cloud Infrastructure.",
          color: "#000000",
        },
        {
          title: "Software Engineer",
          company: "Freelancer",
          company_url: "https://www.linkedin.com/in/niteshpurohit/",
          logo_path: "github_logo.png",
          duration: "March 2012 - June 2014",
          location: "Jodhpur, Rajasthan, India",
          description:
            "Worked on various projects related to Web Development, Mobile Development, Data Science and Cloud Infrastructure.",
          color: "#000000",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "I have worked on multiple projects, some of them are open source and some of them are private. I have experience in building scalable and robust web applications, mobile applications, data science projects and cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "niteshpurohit.png",
    description:
      "Want hire me? or just want to say hi? My inbox is open for all.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I have documented my learning and experiences on my blog. You can read them on my website.",
    link: "https://blog.niteshpurohit.com/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Brampton, Ontario, Canada",
    locality: "Brampton",
    country: "Canada",
    region: "Canada",
    postalCode: "",
    streetAddress: "",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/bxsruRJsysX1nwdq7",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
